
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import configs from '@/configs'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'

@Component({
  name: 'bobbank',
  mixins: [GlobalMixin]
})
export default class PartnerLoginPage extends Vue {
   @Provide()
  public async created () {
    if (!getCurrentQuery('redirect')) { // 配置不带redirect前端自己手动重定向加
      this.setUrl()
    }
    this.init()
  }

    @Emit()
   public setUrl () {
     location.replace(
       'https://passport.benlai.com/bobbank?redirect=https%3A%2F%2Fm.benlai.com'
     )
   }

    @Emit()
    public init () {
      const that = this
      if (window.TMFJSBridge) {
        that.getLoginStatus && that.getLoginStatus()
      } else {
        document.addEventListener('TMFJSBridgeReady', that.getLoginStatus, false)
      }
    }

    @Emit()
    public getLoginStatus () {
      const that = this
      window.TMFJSBridge.invoke('bobGetLoginStatus', {}, function (res: any) {
        if (res.callbackData.isLogin === '0') {
          that.refreshLogin()
        } else {
          that.getUserInfo()
        }
      })
    }

    @Emit()
    public refreshLogin () {
      // 唤起登录
      const that = this
      window.TMFJSBridge.invoke('bobGotoAppletLoginVC', {
      }, function (res: any) {
        // 回调状态："0"成功，"1"失败
        if (res.status === '0') that.getUserInfo()
      })
    }

    @Emit()
    public getUserInfo () {
      const that = this
      this.showLoading()
      window.TMFJSBridge.invoke('bobGetUserInfo', {
        'authInfo': ['phoneNo', 'customerNo']
      }, function (res: any) {
        that.judgePlatform(res.callbackData)
      })
    }
    @Emit()
    public judgePlatform (data: {phoneNo:'', customerNo:''}) {
      const that = this
      window.TMFJSBridge.invoke('bobGetDeviceInfo', {}, function (response: any) {
        if (response.status === '0' && response.callbackData.platform === 'iOS') {
          window.TMFJSBridge.invoke('bobWebPauseHandle', {
            'pauseHandle': 'N'
          }, function () {
            that.decrypt(data)
          })
        } else {
          that.decrypt(data)
        }
      })
    }

    @Emit()
    public async decrypt (authInfo: {phoneNo:'', customerNo:''}) {
      let { phoneNo, customerNo } = authInfo
      let that = this
      const data: any = {
        mobile: phoneNo,
        openid: customerNo,
        grant_type: 'Auth_BankBOB',
        RegistSource: 2
      }
      try {
        const res = await httpHelper.post({
          url: 'authorize/BankBOB',
          contentType: 'application/x-www-form-urlencoded',
          data: data,
          headers: {
            Authorization: configs.basicAuthorization
          }
        })
        this.hideLoading()
        if (res.code === 0) {
          this.goTo(res, 9, 11)
        } else {
          this.$toasted.show(res.msg || res.message)
          setTimeout(() => {
            that.returnFuc()
          }, 3000)
        }
      } catch (err) {
        this.hideLoading()
        this.$toasted.show(err.msg || err.message)
        setTimeout(() => {
          that.returnFuc()
        }, 3000)
      }
    }
    @Emit()
    public returnFuc () {
      let id = new Date().getTime()
      window.TMFJSBridge.invoke('bobCloseApplet', {
        containerId: id
      }, function (res:any) {
      })
    }
    @Emit()
    public showLoading () {
      window.TMFJSBridge.invoke('bobShowAnimationLoading', {
        text: '正在登录'
      }, function (res:any) {
      })
    }
    @Emit()
    public hideLoading () {
      window.TMFJSBridge.invoke('bobHideAnimationLoading', {}, function (res:any) {})
    }
}
